<template>
  <div class="app-container" v-loading="loading">
    <el-header class="new-header">
      <div class="new-header-left">坐席列表</div>
      <div class="new-header-right">
        <el-button class="btn" @click="handleInitAddSeat" type="primary" plain
          >新建坐席
        </el-button>
      </div>
    </el-header>
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      @keyup.enter.native="onSubmit"
    >
      <el-form-item label="商家名称">
        <el-input
          v-model="searchForm.merchantName"
          placeholder="商家名称"
          size="small"
          clearable
          @clear="searchForm.merchantName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="坐席名称">
        <el-input
          v-model="searchForm.seatAccountName"
          placeholder="坐席名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input
          v-model="searchForm.userName"
          placeholder="用户名称"
          size="small"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="坐席主叫号码">
        <el-input
          v-model="searchForm.phone"
          placeholder="坐席主叫号码"
          size="small"
          clearable
          @clear="searchForm.merchantName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="中间号">
        <el-input
          v-model="searchForm.telX"
          placeholder="中间号"
          size="small"
          clearable
          @clear="searchForm.merchantName = null"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-select
          v-model="searchForm.state"
          clearable
          @clear="searchForm.state = null"
          placeholder="状态"
          size="small"
        >
          <el-option label="启用" :value="1"></el-option>
          <el-option label="停用" :value="0"></el-option>
          <el-option label="欠费停机" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应用" prop="state">
        <el-select
          v-model="searchForm.applyId"
          clearable
          @clear="searchForm.applyId = null"
          filterable
          placeholder="应用"
          size="small"
        >
          <el-option
            v-for="item in applyList"
            :key="item.applyId"
            :label="item.applyName"
            :value="item.applyId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务线路">
        <el-select
          v-model="searchForm.businessLineId"
          clearable
          @clear="searchForm.businessLineId = null"
          filterable
          placeholder="业务线路"
          size="small"
        >
          <el-option
            v-for="item in businessLineList"
            :key="item.businessLineId"
            :label="item.businessLineName"
            :value="item.businessLineId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" icon="search" size="small"
          >搜索</el-button
        >
        <el-button
          type="primary"
          @click="handleExportSeatAccountList"
          icon="search"
          size="small"
          >导出</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      style="width: 100%"
      ref="list"
      stripe
      size="small"
    >
      <el-table-column
        prop="seatAccountName"
        label="坐席名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="businessLineName"
        label="业务线路"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="applyName"
        label="应用"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="telX"
        label="中间号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantName"
        label="商家名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="用户名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="坐席主叫号码"
        align="center"
      ></el-table-column>
      <el-table-column
          prop="totalMinutes"
          label="总分钟数"
          align="center"
      ></el-table-column>
      <el-table-column
        prop="usedMinutes"
        label="已使用分钟数"
        align="center"
      ></el-table-column>
      <!--            <el-table-column-->
      <!--                    prop="remainMinutes"-->
      <!--                    label="剩余分钟数"-->
      <!--                    align="center"-->
      <!--            ></el-table-column>-->

      <el-table-column
        prop="minimumCost"
        label="当月最低消费"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="usedCost"
        label="当月已用"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="adminName"
        label="创建人"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="stateName" label="状态" align="center" width="200">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.state"
            v-if="scope.row.state !== 4"
            active-text="启用"
            inactive-text="停用"
            :active-value="1"
            :inactive-value="0"
            @change="handleChangeSeatState(scope.row)"
          >
          </el-switch>
          <span v-if="scope.row.state === 4">欠费停机</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top" content="编辑">
            <el-button
              type="text"
              @click="handleInitModifySeat(scope.row)"
              size="small"
            >
              编辑
            </el-button>
          </el-tooltip>
          <el-tooltip placement="top" content="删除">
            <el-button
              type="text"
              style="color: red"
              @click="handleDeleteSeat(scope.row)"
              size="small"
            >
              删除
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageVo.current"
        :page-sizes="[2, 10, 20, 50, 100]"
        :page-size="pageVo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      >
      </el-pagination>
    </div>
    <el-dialog title="新建坐席" :visible.sync="dialogForAddSeat">
      <el-form
        ref="addForm"
        :model="addForm"
        :rules="rules"
        size="small"
        label-width="150px"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="坐席名称:" prop="seatAccountName">
              <el-input
                v-model="addForm.seatAccountName"
                placeholder="坐席名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="商家:" prop="merchantId">
              <el-select
                v-model="addForm.merchantId"
                clearable
                @clear="addForm.merchantId = null"
                @change="initSelections"
                filterable
                placeholder="商家"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in merchantList"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务线路开关">
              <el-switch
                v-model="addForm.switchBusinessLine"
                active-text="开"
                inactive-text="关"
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </el-form-item>
            <el-form-item
              label="业务线路:"
              prop="businessLineId"
              v-if="addForm.switchBusinessLine"
            >
              <el-select
                v-model="addForm.businessLineId"
                clearable
                @clear="addForm.businessLineId = null"
                filterable
                placeholder="业务线路"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in businessLineOptions"
                  :key="item.businessLineId"
                  :label="item.businessLineName"
                  :value="item.businessLineId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="应用:" prop="applyId" v-else>
              <el-select
                v-model="addForm.applyId"
                clearable
                @clear="addForm.applyId = null"
                filterable
                placeholder="应用"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in applyOptions"
                  :key="item.applyId"
                  :label="item.applyName"
                  :value="item.applyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中间号:" prop="telX">
              <el-input v-model="addForm.telX" placeholder="中间号"></el-input>
            </el-form-item>
            <el-form-item label="用户:" prop="userId">
              <el-select
                v-model="addForm.userId"
                clearable
                @clear="addForm.userId = null"
                filterable
                placeholder="用户"
                size="small"
                style="width: 100%"
                :filter-method="addSeatUserFileter"
                @focus="tempUserList = userList"
              >
                <el-option
                  v-for="item in tempUserList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="float: left">{{ item.userName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.phone
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="计费规则:" prop="ruleId">
              <el-select v-model="addForm.ruleId" placeholder="计费规则" filterable style="width: 100%" @change="calculationSeatCost">
                <el-option v-for="item in ruleList" :key="item.ruleId" :label="item.ruleName" :value="item.ruleId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="总分钟数" prop="totalMinutes">
              <el-input-number
                  :min="0"
                  controls-position="right"
                  v-model="addForm.totalMinutes"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="当月最低消费" prop="minimumCost">
              <el-input-number
                  controls-position="right"
                  :min="0"
                  v-model="addForm.minimumCost"
                  style="width: 70%"
              />
            </el-form-item>
            <el-form-item label="套餐内单价(分钟):" prop="priceInPackage">
              <el-input-number
                  controls-position="right"
                  v-model="addForm.priceInPackage"
                  placeholder="请输入套餐内单价(分钟)"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="套餐外单价(分钟):" prop="priceOutsidePackage">
              <el-input-number
                  controls-position="right"
                  v-model="addForm.priceOutsidePackage"
                  placeholder="请输入套餐外单价(分钟)"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="次月最低消费" prop="nextMinimumCost">
              <el-input-number
                  controls-position="right"
                  :min="0"
                  v-model="addForm.nextMinimumCost"
                  style="width: 70%"
              />
            </el-form-item>
            <el-form-item label="次月总分钟数" prop="nextTotalMinutes">
              <el-input-number
                  :min="0"
                  controls-position="right"
                  v-model="addForm.nextTotalMinutes"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="充值金额" v-if="addForm.chargingType == 1">
              <el-input-number
                :min="0"
                controls-position="right"
                v-model="addForm.topUpAmount"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForAddSeat = false"
          >取消
        </el-button>
        <el-button type="primary" @click="handleAddSeat">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑坐席" :visible.sync="dialogForModifySeat">
      <el-form
        ref="modifyForm"
        :model="modifyForm"
        :rules="rules"
        label-width="150px"
      >
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <el-form-item label="坐席名称:" prop="seatAccountName">
              <el-input
                v-model="modifyForm.seatAccountName"
                placeholder="坐席名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="商家:" prop="merchantId">
              <el-select
                disabled
                v-model="modifyForm.merchantId"
                clearable
                @clear="modifyForm.merchantId = null"
                filterable
                placeholder="商家"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in merchantList"
                  :key="item.merchantId"
                  :label="item.merchantName"
                  :value="item.merchantId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="业务线路开关">
              <el-switch
                v-model="modifyForm.switchBusinessLine"
                active-text="开"
                inactive-text="关"
                :active-value="true"
                :inactive-value="false"
              >
              </el-switch>
            </el-form-item>
            <el-form-item
              label="业务线路:"
              prop="businessLineId"
              v-if="modifyForm.switchBusinessLine"
            >
              <el-select
                v-model="modifyForm.businessLineId"
                clearable
                @clear="modifyForm.businessLineId = null"
                filterable
                placeholder="业务线路"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in businessLineOptions"
                  :key="item.businessLineId"
                  :label="item.businessLineName"
                  :value="item.businessLineId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="应用:" prop="applyId" v-else>
              <el-select
                v-model="modifyForm.applyId"
                clearable
                @clear="modifyForm.applyId = null"
                filterable
                placeholder="应用"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in applyOptions"
                  :key="item.applyId"
                  :label="item.applyName"
                  :value="item.applyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中间号:" prop="telX">
              <el-input
                v-model="modifyForm.telX"
                placeholder="中间号"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户:" prop="userId">
              <el-select
                v-model="modifyForm.userId"
                clearable
                @clear="modifyForm.userId = null"
                filterable
                :filter-method="modifySeatUserFileter"
                @focus="tempModifyUserList = modifyUserList"
                placeholder="用户"
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tempModifyUserList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="float: left">{{ item.userName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.phone
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="modifyForm.state"
                clearable
                @clear="modifyForm.state = null"
                placeholder="状态"
                size="small"
                style="width: 100%"
              >
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="0"></el-option>
                <el-option label="欠费停机" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="总分钟数" prop="totalMinutes">
              <el-input-number
                  :min="0"
                  controls-position="right"
                  v-model="modifyForm.totalMinutes"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="当月最低消费" prop="minimumCost">
              <el-input-number
                  controls-position="right"
                  :min="0"
                  v-model="modifyForm.minimumCost"
                  style="width: 70%"
              />
            </el-form-item>
            <el-form-item label="套餐内单价(分钟):" prop="priceInPackage">
              <el-input-number
                  controls-position="right"
                  v-model="modifyForm.priceInPackage"
                  placeholder="请输入套餐内单价(分钟)"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="套餐外单价(分钟):" prop="priceOutsidePackage">
              <el-input-number
                  controls-position="right"
                  v-model="modifyForm.priceOutsidePackage"
                  placeholder="请输入套餐外单价(分钟)"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="次月最低消费" prop="nextMinimumCost">
              <el-input-number
                  controls-position="right"
                  :min="0"
                  v-model="modifyForm.nextMinimumCost"
                  style="width: 70%"
              />
            </el-form-item>
            <el-form-item label="次月总分钟数" prop="nextTotalMinutes">
              <el-input-number
                  :min="0"
                  controls-position="right"
                  v-model="modifyForm.nextTotalMinutes"
                  style="width: 70%"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="info" @click="dialogForModifySeat = false"
          >取消
        </el-button>
        <el-button type="primary" @click="handleModifySeat">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  createSeatAccount,
  deleteSeat,
  editSeatAccount,
  editSeatAccountState,
  exportSeatAccountList,
  getApplyList,
  getMerchantNoPage,
  getSeatAccountList,
  getUserListNoBindSeat,
  initSeatAccount,
  listMerchantBusinessLine,
  getBusinessLine,
  listBusinessLine, listBillingAcct, getAccountRuleListNoPage,
} from "../../api";

export default {
  name: "",
  data() {
    return {
      dialogForAddSeat: false,
      dialogForModifySeat: false,
      adminId: this.$store.getters.adminId,
      searchForm: {
        adminId: this.$store.getters.adminId,
        businessLineId: null,
        merchantName: "",
        seatAccountName: "",
        userName: "",
        phone: "",
        telX: "",
        state: null,
        lineId: null,
        applyId: null,
      },
      ruleList: [],
      addForm: {
        adminId: this.$store.getters.adminId,
        switchBusinessLine: false,
        seatAccountName: null,
        merchantId: null,
        applyId: null,
        telX: null,
        userId: null,
        totalMinutes: 0,
        nextTotalMinutes: 0,
        topUpAmount: 0,
        businessLineId: null,
        minimumCost: 0,
        nextMinimumCost: 0,
        priceInPackage: "",
        priceOutsidePackage: "",
        ruleId: null,
      },
      modifyForm: {
        adminId: this.$store.getters.adminId,
        switchBusinessLine: true,
        seatAccountName: null,
        merchantId: null,
        applyId: null,
        telX: null,
        userId: null,
        state: null,
        totalMinutes: 0,
        nextTotalMinutes: 0,
        balance: null,
        minimumCost: null,
        nextMinimumCost: 0,
        usedCost: null,
        businessLineId: null,
        priceInPackage: "",
        priceOutsidePackage: "",
      },
      list: [],
      userList: [], //userId userName phone
      applyOptions: [], //userId userName phone
      businessLineOptions: [],
      lineList: [], //lineId	 lineName
      merchantList: [], //merchantId merchantName
      pageVo: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      loading: false,
      tempUserList: [],
      modifyUserList: [],
      tempModifyUserList: [],
      applyList: [],
      businessLineList: [],
      rules: {
        seatAccountName: [
          { required: true, message: "请输入坐席名称", trigger: "blur" },
        ],
        merchantId: [
          { required: true, message: "请选择商家", trigger: "change" },
        ],
        priceInPackage: [{required: true, message: "请输入套餐内单价(分钟)", trigger: "blur"},],
        priceOutsidePackage: [{required: true, message: "请输入套餐外单价(分钟)", trigger: "blur"},],
        totalMinutes: [{required: true, message: "请输入总分钟数", trigger: "blur"},],
        nextTotalMinutes: [{required: true, message: "请输入次月总分钟数", trigger: "blur"},],
        minimumCost: [{required: true, message: "请输入当月抵消", trigger: "blur"},],
        nextMinimumCost: [{required: true, message: "请输入次月抵消", trigger: "blur"},],
      },
    };
  },
  created() {
    this.loadSeatList();
    this.handleLoadLineList();
    this.loadAllMerchantList();
    this.handleLoadBusinessLineList();
    // this.handleInitAddSeat();
    this.handleInitSelections();
  },
  computed: {
    // showAddTelX() {
    //   if (this.addForm.lineId) {
    //     let temp = this.lineList.find((i) => i.lineId == this.addForm.lineId);
    //     return temp.needPhoneType === 1;
    //   }
    //   return false;
    // },
    showModifyTelX() {
      if (this.modifyForm.lineId) {
        let temp = this.lineList.find(
          (i) => i.lineId == this.modifyForm.lineId
        );
        return temp.needPhoneType === 1;
      }
      return false;
    },
  },
  methods: {
    calculationSeatCost(item) {
      let ruleInfoList = this.ruleList.filter((rule) => {return rule.ruleId == item});
      let ruleInfo;
      if (ruleInfoList && ruleInfoList.length > 0) {
        ruleInfo = ruleInfoList[0];
      } else {
        return;
      }
      this.addForm.minimumCost = ruleInfo.minimumCost;
      this.addForm.totalMinutes = ruleInfo.totalMinutes;
      this.addForm.priceInPackage = ruleInfo.priceInPackage;
      this.addForm.priceOutsidePackage = ruleInfo.priceOutsidePackage;
      this.addForm.nextMinimumCost = ruleInfo.minimumCost;
      this.addForm.nextTotalMinutes = ruleInfo.totalMinutes;
    },
    async handleInitSelections() {
      this.ruleList = await getAccountRuleListNoPage({ adminId: this.adminId });
    },
    handleExportSeatAccountList() {
      this.$confirm(`确定导出坐席吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      }).then(() => {
        exportSeatAccountList({ ...this.searchForm });
      });
    },
    async handleInitAddSeat() {
      this.applyOptions = await getApplyList({
        adminId: this.searchForm.adminId,
      });
      this.dialogForAddSeat = true;
    },
    async loadAllMerchantList() {
      this.merchantList = await getMerchantNoPage({
        adminId: this.$store.getters.adminId,
      });
    },
    async handleLoadLineList() {
      let data = await getApplyList({ adminId: this.$store.getters.adminId });
      this.applyList = data;
    },
    async handleLoadBusinessLineList() {
      this.businessLineList = await listBusinessLine({
        adminId: this.$store.getters.adminId,
      });
    },
    handleDeleteSeat(row) {
      if (row.seatAccountId) {
        this.$confirm(`确定删除坐席${row.seatAccountName}吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteSeat({
            seatAccountId: row.seatAccountId,
            adminId: this.$store.getters.adminId,
          }).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "删除成功",
              });
              this.loadSeatList();
            }
          });
        });
      }
    },
    handleChangeSeatState(row) {
      let param = {
        seatAccountId: row.seatAccountId,
        state: row.state,
        adminId: this.$store.getters.adminId,
      };
      editSeatAccountState(param).then((data) => {
        if (data) {
          let message =
            row.state === 1
              ? `启用坐席${row.seatAccountName}成功`
              : `停用坐席${row.seatAccountName}成功`;
          this.$notify({
            type: "success",
            title: "成功",
            message: message,
          });
        }
      });
    },
    modifySeatUserFileter(val) {
      this.tempModifyUserList = this.modifyUserList.filter(
        (item) =>
          item.phone.indexOf(val) !== -1 || item.userName.indexOf(val) !== -1
      );
    },
    addSeatUserFileter(val) {
      this.tempUserList = this.userList.filter(
        (item) =>
          item.phone.indexOf(val) !== -1 || item.userName.indexOf(val) !== -1
      );
    },
    async initSelections(merchantId) {
      this.userList = [];
      this.addForm.topUpAmount = null;
      let data = await initSeatAccount({
        adminId: this.searchForm.adminId,
        merchantId: merchantId,
      });
      if (data.applyList && data.applyList.length > 0) {
        this.applyOptions = data.applyList;
      }
      this.userList = data.userList;
      this.addForm.chargingType = data.chargingType;
      //坐席计费
      if (data.chargingType && data.chargingType == 1) {
        this.addForm.topUpAmount = data.minimumCost;
      }
      let businessLineData = await listMerchantBusinessLine({
        adminId: this.searchForm.adminId,
        merchantId: merchantId,
      });
      this.businessLineOptions = businessLineData;
      this.addForm.businessLineId =
        businessLineData.length > 0 ? businessLineData[0].businessLineId : null;
      // if(this.addForm.businessLineId){
      //     await this.initSelectionsBusinessLine(this.addForm.businessLineId)
      // }
    },
    async loadMerchantBusinessLine() {
      let businessLineData = await listMerchantBusinessLine({
        adminId: this.searchForm.adminId,
        merchantId: this.modifyForm.merchantId,
      });
      this.businessLineOptions = businessLineData;
    },
    async initSelectionsBusinessLine(businessLineId) {
      this.applyOptions = [];
      this.addForm.applyId = null;
      this.modifyForm.applyId = null;
      if (businessLineId) {
        let data = await getBusinessLine({
          adminId: this.searchForm.adminId,
          businessLineId: businessLineId,
        });
        this.applyOptions = data.applyList;
      }
    },
    handleAddSeat() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.addForm,
          };
          // //如果不需要中间号，则删除中间号参数
          // if (!this.showAddTelX) {
          //   param.telX = null;
          // }
          if (this.addForm.switchBusinessLine) {
            delete param.applyId;
            delete param.applyName;
          } else {
            delete param.businessLineId;
            delete param.businessLineName;
          }
          createSeatAccount(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "创建坐席成功",
              });
              this.dialogForAddSeat = false;
              this.loadSeatList();
            }
          });
        }
      });
    },
    async handleInitModifySeat(row) {
      Object.assign(this.modifyForm, row);
      this.modifyForm.state = row.state;
      if (row.businessLineId) {
        this.modifyForm.switchBusinessLine = true;
      } else {
        this.modifyForm.switchBusinessLine = false;
      }
      this.modifyForm.seatAccountName = row.seatAccountName;
      this.modifyForm.merchantId = row.merchantId;
      this.modifyForm.applyId = row.applyId;
      this.modifyForm.telX = row.telX;
      this.modifyForm.userId = row.userId;
      this.modifyForm.totalMinutes = row.totalMinutes;
      this.modifyForm.nextTotalMinutes = row.nextTotalMinutes;
      this.modifyForm.priceInPackage = row.priceInPackage;
      this.modifyForm.priceOutsidePackage = row.priceOutsidePackage;
      this.modifyForm.minimumCost = row.minimumCost;
      this.modifyForm.nextMinimumCost = row.nextMinimumCost;

      this.modifyForm.businessLineId = this.modifyForm.businessLineId
        ? this.modifyForm.businessLineId * 1
        : null;
      this.modifyForm.lineId = this.modifyForm.lineId
        ? this.modifyForm.lineId * 1
        : null;
      this.modifyForm.merchantId = this.modifyForm.merchantId
        ? this.modifyForm.merchantId * 1
        : null;
      this.modifyForm.userId = this.modifyForm.userId
        ? this.modifyForm.userId * 1
        : null;
      let data = await initSeatAccount({
        adminId: this.$store.getters.adminId,
        merchantId: row.merchantId,
      });
      let user = await getUserListNoBindSeat({
        adminId: this.$store.getters.adminId,
        seatAccountId: row.seatAccountId,
      });
      this.modifyUserList = user;
      this.tempModifyUserList = user;
      this.applyOptions = await getApplyList({
        adminId: this.searchForm.adminId,
      });
      await this.loadMerchantBusinessLine();
      this.dialogForModifySeat = true;
      console.log(this.modifyForm);
    },
    handleModifySeat() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          let param = {
            ...this.modifyForm,
          };
          if (this.modifyForm.switchBusinessLine) {
            delete param.applyId;
            delete param.applyName;
          } else {
            delete param.businessLineId;
            delete param.businessLineName;
          }
          editSeatAccount(param).then((data) => {
            if (data) {
              this.$notify({
                type: "success",
                title: "成功",
                message: "编辑坐席成功",
              });
              this.dialogForModifySeat = false;
              this.loadSeatList();
            }
          });
        }
      });
    },
    onSubmit() {
      this.pageVo.current = 1;
      this.loadSeatList();
    },
    handleSizeChange(val) {
      this.pageVo.size = val;
      this.loadSeatList();
    },
    handleCurrentChange(val) {
      this.pageVo.current = val;
      this.loadSeatList();
    },
    loadSeatList() {
      let params = {
        ...this.pageVo,
        params: this.searchForm,
      };
      this.loading = true;
      getSeatAccountList(params).then((data) => {
        console.log(data);
        this.list = data.records;
        this.totalCount = data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss"></style>
